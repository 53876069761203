<template>
	<!-- 预出库单 -->
	<div class="container">

		<div class="search">
			<div class="search-l">
				<div class="l-item" style="width: 40%;">
					<div class="item-label">配货日期:</div>
					<div class="item-input" style="width: 80%;">
						<el-date-picker v-model="dateVal" type="daterange" range-separator="至" start-placeholder="开始日期"
							end-placeholder="结束日期" value-format="yyyy-MM-dd" style="width: 100%;">
						</el-date-picker>
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">联系人:</div>
					<div class="item-input">
						<el-input v-model="searchForm.contacts" placeholder="请输入联系人姓名" />
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">联系电话:</div>
					<div class="item-input">
						<el-input v-model="searchForm.phone" placeholder="请输入联系电话" />
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">包含商品:</div>
					<div class="item-input">
						<el-input v-model="searchForm.goods_name" placeholder="请输入包含商品的名称关键字" />
					</div>
				</div>
				<div class="l-item">
					<div class="item-label">配送路线:</div>
					<div class="item-input">
						<el-select v-model="searchForm.delivery_line_id" placeholder="请选择">
							<el-option v-for="item in routeList" :key="item.id" :label="item.title" :value="item.id">
							</el-option>
						</el-select>
					</div>
				</div>
				<div class="l-item">
					<el-button type="warning"
						@click.stop="loading = true, page = 1, searchForm.begin = dateVal[0], searchForm.end = dateVal[1], getList()">搜索</el-button>
					<el-button
						@click.stop="loading = true, page = 1, pageNum = 10, searchForm = {}, searchForm.begin = dateVal[0], searchForm.end = dateVal[1], getList()">重置</el-button>
				</div>
				<i></i>
				<i></i>
				<i></i>
				<i></i>
			</div>
		</div>
		<div class="table">

			<div id="positionContainer" style="width:100%; height:93%"></div>
		</div>
	</div>

</template>

<script>
	export default {
		data() {
			return {
				currentDateTime: this.getCurrentDateTime(),
				imageUrl: "https://static.tuanhuo.howtec.cc/images/logo.png",
				normalName: "配送站点",
				errName: "异常地址",
				dateVal: [],
				searchForm: {
					begin: '',
					end: '',
				},
				tableData: [],
				loading: true,
				page: 1,
				pageNum: 10,
				total: 0,
				orderDate: '',
				// 提货方式
				deliveryTypeList: [{
						id: 1,
						name: '自提'
					},
					{
						id: 2,
						name: '配送'
					}
				],
				warehouseList: [], //仓库
				routeList: [], //配送路线
				// 供应商信息
				supplier_info: {},
				// 选择表格的数据
				selectionList: [],
				exportDisabled: false, //打印禁用
				operationDisabled: false, //批量确认、取消禁用
				// 路线规划
				mapData: {
					short: [],
					fast: []
				},
				routePlannForm: {
					warehouse_name: "",
					delivery_type: 2,
					begin: '',
					end: '',
					force: '0',
				},
				routePlannDialog: false,
				mapDialog: false,
				routePlannType: 1,
				routePlannList: [{
						id: 1,
						name: '最短距离'
					},
					{
						id: 2,
						name: '最快时间'
					}
				],
				fullscreenLoading: false,
				mapKey: "",
				qrcode: null,
				positionDialog: false,
				summaryDialog: false,
				summaryTableData: [],
				//修改地址
				addressForm: {},
				addressDialog: false,
				addressRouteLine: [],
				//打印纸
				pageType: 1,
				pageTypeItem: {
					id: 1,
					name: '针式三等分多联纸',
					width: 2410,
					height: 930
				},
				pageTypeList: [{
					id: 1,
					name: '针式三等分多联纸',
					width: 2410,
					height: 930
				}, {
					id: 2,
					name: '针式二等分多联纸',
					width: 2410,
					height: 1400
				}, {
					id: 3,
					name: '针式一等分多联纸',
					width: 2410,
					height: 2800
				}, {
					id: 4,
					name: '标准A4纸',
					width: 2100,
					height: 2970
				}],
				printMode: 1
			}
		},
		mounted() {
			this.supplier_info = JSON.parse(sessionStorage.getItem('supplier_info'))
			// 列表
			this.getList()
			// 时间
			this.dateVal = [this.getCurrentDate(), this.getCurrentDate()]
			this.searchForm.begin = this.dateVal[0]
			this.searchForm.end = this.dateVal[1]
			this.routePlannForm.begin = this.dateVal[0]
			this.routePlannForm.end = this.dateVal[1]
			this.routePlannForm.force = '0'
			// 路线
			this.getRouteList()
			// 仓库
			this.getWarehouse()
			// 检查是否有未配商品
			this.checkNoDelivery()

			this.pageType = localStorage.getItem("defaultPageType") ?? '1';
			this.pageType = Number(this.pageType)
			if (localStorage.getItem("defaultPageTypeItem")) {
				this.pageTypeItem = JSON.parse(localStorage.getItem("defaultPageTypeItem"));
			} else {
				this.pageTypeItem = {
					id: 1,
					name: '针式三等分多联纸',
					width: 2410,
					height: 930
				};
			}
		},
		methods: {
			selectPageType(value) {
				let that = this
				this.pageTypeList.forEach(item => {
					if (item.id == value) {
						that.pageTypeItem = item
					}
				})
				localStorage.setItem("defaultPageType", value);
				localStorage.setItem("defaultPageTypeItem", JSON.stringify(that.pageTypeItem));
			},
			checkNoDelivery() {
				this.$http.post(`erp/v1/preck/check_no_delivery`, {}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					this.loading = false
					if (code == 200 && data > 0) {

						this.$alert('今日有' + data + '件商品未生成配送单，请重新生成', '警告', {
							confirmButtonText: '确定',
							callback: action => {

							}
						});
					}
				});
			},

			GenerateQRCode() {
				if (this.qrcode == null) {
					this.qrcode = new QRCode(this.$refs.qrcode, {
						width: 128, // 二维码宽度，单位像素
						height: 128, // 二维码高度，单位像素
						text: "https://tuanhuo.howtec.cc/driving_line?key=" + this.mapData.driverKey + "&expire=" +
							this.mapData.driverKeyExpire, // 生成二维码的链接
					})
				} else {
					this.qrcode.makeCode("https://tuanhuo.howtec.cc/driving_line?key=" + this.mapData.driverKey +
						"&expire=" +
						this.mapData.driverKeyExpire)
				}

			},

			// 获取当前年月日
			getCurrentDate() {
				const today = new Date();
				let year = today.getFullYear();
				let month = (today.getMonth() + 1).toString().padStart(2, '0');
				let day = today.getDate().toString().padStart(2, '0');
				return `${year}-${month}-${day}`;
			},
			getList() {
				this.$http.post(`/erp/v1/preck/lst_address_lng_lat`, {
					'pre_ckd_ids': '',
					...this.searchForm,
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.fullscreenLoading = false;
						this.positionDialog = true
						let addressData = data.addressList
						let mapGeometries = []
						let warehouse = {
							lat: "",
							lng: ""
						}
						this.mapKey = data.mapKey

						addressData.forEach((item, index) => {
							mapGeometries.push({
								styleId: 'marker',
								position: new TMap.LatLng(item.lat, item.lng),
								content: item.contacts
							})
							warehouse.lat = item.warehouse.lat
							warehouse.lng = item.warehouse.lng

						})

						// 地图
						this.$nextTick(() => {
							if (this.mapPosition == null) {
								this.mapPosition = new TMap.Map('positionContainer', {
									zoom: 12,
									disableDefaultUI: true,
								});
							}
							this.mapPosition.setCenter(new TMap.LatLng(warehouse.lat, warehouse.lng))
							var marker = new TMap.MultiMarker({
								id: 'marker-layer1',
								map: this.mapPosition,
								styles: {
									marker: new TMap.MarkerStyle({
										width: 20,
										height: 28,
										direction: 'top',
									})

								},
								geometries: mapGeometries,
							});
						})

					} else {
						this.$message.error(msg);
					}
				});
			},

			// 所属路线
			getRouteList() {
				this.$http.post(`erp/v1/delivery_line/all`).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.routeList = data
						this.addressRouteLine = data
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 仓库
			getWarehouse() {
				this.$http.post(`erp/v1/warehouse/lst`, {
					pageNum: 100
				}).then((res) => {
					const {
						code,
						data,
						msg
					} = res.data
					if (code == 200) {
						this.warehouseList = data.rows
					} else {
						this.$message.error(msg);
					}
				});
			},
			// 获取当前年月日时分秒
			getCurrentDateTime() {
				const now = new Date();
				const year = now.getFullYear();
				const month = this.padNumber(now.getMonth() + 1); // 月份是从0开始的
				const day = this.padNumber(now.getDate());
				const hours = this.padNumber(now.getHours());
				const minutes = this.padNumber(now.getMinutes());
				const seconds = this.padNumber(now.getSeconds());
				return (
					year +
					'-' +
					month +
					'-' +
					day +
					' ' +
					hours +
					':' +
					minutes +
					':' +
					seconds
				);
			},
			padNumber(num) {
				return num < 10 ? '0' + num : num;
			},
		},
	}
</script>

<style lang="scss" scoped>
	.map-dialog {
		width: 100%;
		height: 100%;
		display: flex;
		justify-content: space-between;

		.dialog-l {
			width: 80%;
			height: 100%;

			.l-select {
				display: flex;
				align-items: center;
				margin-bottom: 10px;
			}
		}

		.dialog-r {
			width: 18%;
			display: flex;
			flex-direction: column;
			overflow-y: auto;

			.r-item {
				margin-bottom: 10px;
			}
		}
	}

	.qrcode-range {
		left: 20px;
		top: 80px;
		position: absolute;
		display: flex;
		background-color: #fff;
		z-index: 999;
		color: #000;
		flex-direction: column;
		text-align: center;
	}
</style>